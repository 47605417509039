var ready_rx_advices, set_advice_positions;

set_advice_positions = function () {
    // loop through and give each task a data-pos
    // attribute that holds its position in the DOM
    $('.rx_pad_advice_item').each(function (i) {
        $(this).attr("data-pos", i + 1);
    });
}

ready_rx_advices = function () {

    // call set_positions function
    set_advice_positions();

    $('.rx_pad_advices-sortable').sortable({
        handle: '.handle'
    });

    // after the order changes
    $('.rx_pad_advices-sortable').sortable().bind('sortupdate', function (e, ui) {
        // array to store new order
        updated_order = []
        // set the updated positions
        set_advice_positions();

        // populate the updated_order array with the new task positions
        $('.rx_pad_advice_item').each(function (i) {
            updated_order.push({id: $(this).data("id"), position: i + 1});
        });

        // send the updated order via ajax
        $.ajax({
            type: "PUT",
            url: '/rx_pad/advices/sort',
            data: {order: updated_order}
        });
    });
}

var ready_rx_instructions, set_instruction_positions;

set_instruction_positions = function () {
    // loop through and give each task a data-pos
    // attribute that holds its position in the DOM
    $('.rx_pad_instruction_item').each(function (i) {
        $(this).attr("data-pos", i + 1);
    });
}

ready_rx_instructions = function () {

    // call set_positions function
    set_instruction_positions();

    $('.rx_pad_instructions-sortable').sortable({
        handle: '.handle'
    });

    // after the order changes
    $('.rx_pad_instructions-sortable').sortable().bind('sortupdate', function (e, ui) {
        // array to store new order
        updated_order = []
        // set the updated positions
        set_instruction_positions();

        // populate the updated_order array with the new task positions
        $('.rx_pad_instruction_item').each(function (i) {
            updated_order.push({id: $(this).data("id"), position: i + 1});
        });

        // send the updated order via ajax
        $.ajax({
            type: "PUT",
            url: '/rx_pad/instructions/sort',
            data: {order: updated_order}
        });
    });
}

jQuery(document).on('turbolinks:load', function () {
    "use strict";

    ready_rx_advices();
    ready_rx_instructions();

    console.log('Hi')

    var $myFuelGauge;

    $( function () {
        $myFuelGauge = $("div#fuel-gauge").dynameter({
            width: 400,
            label: 'health score',
            value: 88.5,
            min: 0.0,
            max: 100.0,
            unit: 'percentage',
            regions: { // Value-keys and color-refs
                0: 'error',
                35.5: 'warn',
                60.5: 'normal'
            }
        });

    });

    $(function () {
        return $('#doctor_chamber_facility_id').chosen({
            allow_single_deselect: true,
            no_results_text: 'No results matched'
            //width: '200px'
        });

    });
});



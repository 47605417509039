// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('popper.js')

require("plugins/jquery.sortable")
require("plugins/jquery.dynameter")
require("plugins/jquery.tempgauge")
// require('perfect-scrollbar')

// require("admin_theme_js/popper")
// require("admin_theme_js/moment")
// require("admin_theme_js/chart")
// require("admin_theme_js/select2.full")
// require("admin_theme_js/jquery.barrating")
// require("admin_theme_js/ckeditor")
// require("admin_theme_js/validator")
// require("admin_theme_js/daterangepicker")
// require("admin_theme_js/ion.rangeSlider")
// require("admin_theme_js/dropzone")
// require("admin_theme_js/mindmup-editabletable")
// require("admin_theme_js/jquery.dataTables")
// require("admin_theme_js/dataTables.bootstrap")
// require("admin_theme_js/fullcalendar")
// require("admin_theme_js/perfect-scrollbar.jquery")
require("admin_theme_js/tether")
// require("admin_theme_js/slick")
// require("admin_theme_js/util")
// require("admin_theme_js/alert")
// require("admin_theme_js/button")
// require("admin_theme_js/carousel")
// require("admin_theme_js/collapse")
// require("admin_theme_js/dropdown")
// require("admin_theme_js/modal")
// require("admin_theme_js/tab")
// require("admin_theme_js/tooltip")
// require("admin_theme_js/popover")
// require("admin_theme_js/demo_customizer")
// require("admin_theme_js/main")
require("admin_theme_js/init")